$primary: #FF5D17;
$warning: #FFC107;
$danger: #DC3545;
$success: #28A745;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@import "font";


@import "layout";
@import "styles";

@import "partials/navbar";
@import "partials/card";
@import "partials/auth";
@import "partials/report";
@import "partials/assessment";
@import "partials/my_account";
@import "partials/payment_scrren";
@import "partials/users";
@import "partials/buttons";
@import "partials/table";
@import "partials/assessment_templates";
@import "partials/our_network";
@import "partials/competency";
@import "partials/score_icon";
@import "partials/plan_card";

@import "plugins/proSidebar";

