.main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 67px;
    background-color: #F8F9FA;

    .loading-holder
    {
        height: 100%;
    }

    .page-actions-section
    {
        padding: 15px;
    }

    .content
    {
        padding: 20px;

        .assessment-sections
        {
            .list-group-item {
                background-color: #F8F9FA;
            }
        }
    }

    .login-background {
        background-image: url("./../../assets/images/login-backgroup.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .login-container
    {
        width: 100%;
        max-width: 650px;
        min-width: 350px;
    }
    .auth-btn
    {
        font-weight: 700;
    }

    .map-container
    {
        height: 400px;
        width: 100%;
    }
}

.address-combo
{
    position: relative;
    .list-group
    {
        position: absolute;
        top:50px;
        border-radius: 4px;
        z-index: 10;
        box-shadow: 0 4px 24px 0 $gray-100;
    }
}

.score-icon
{
    position: absolute;
    top:0;
    left: 0;
}
