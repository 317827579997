.report-container {
    .rating-improvement-statement
    {
        font-size: 12px;
    }
    .section-score-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }

    .section-score {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .score-title-indicator
    {
        width: 16px;
        height: 16px;
        margin-right: 15px;
    }

    .accordion-item
    {
        margin: 15px 0 !important;
        border: 0 !important;
    }

    .accordion-button:not(.collapsed) {
        color: $body-color;
        background-color: $white;
        box-shadow: none !important;
    }
    .accordion-button
    {
        font-weight: 600;
    }
}

.text-high {
    color: $danger;
}

.text-medium {
    color: #E67F23;
}

.text-low {
    color: $warning;
}

.text-no {
    color: $success;
}

.bg-high {
    background: $danger;
}

.bg-medium {
    background: #E67F23;
}

.bg-low {
    background: $warning;
}

.bg-no {
    background: $success;
}


.text-high-competency {
    color: $success;
}

.text-medium-competency {
    color: $warning;
}

.text-low-competency {
    color: $danger;
}

.text-no-competency {
    color: $danger;
}



.bg-high-competency {
    background: $success;
}

.bg-medium-competency {
    background: $warning;
}

.bg-low-competency {
    background: $danger;
}

.bg-no-competency {
    background: $success;
}