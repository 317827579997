.certificate-holder {
  max-width: 800px;
}

.audit-request-form
{
  .certificate-card {
    height: auto !important;
    width: auto !important;
  }
}

.certificate-card {
  background-color: #F8F9FA;
  height: 1132px;
  width: 800px;

  .certificate-scores {
    background-color: #ffffff;

    .section-score {
      font-size: 12px;
    }

    .section-score-title {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .certificate-logo {
    width: 100px;
  }

  p {
    font-size: 13px;
  }

  .certificate-information {
    p {
      margin-bottom: 5px;
    }
  }

  .certificate-signature {
    height: 50px;
  }

  .certificate-footer-section {
    .fra-link {
      font-size: 16px;
    }
  }
}

.request-sent {
  .card {
    background-color: #F8F9FA;
  }
}

.audit-request-form {
  textarea {
    min-height: 150px;
  }
}

.full-report-container {
  max-width: 750px;

  .report-answers {
    color: #6C757D;
  }
}

.status-field {
  white-space: nowrap;
}


.question-heading
{
  color: $primary !important;
}

.question-mark
{
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: $primary;
  color: $white;
  font-size: 12px;
  padding: 2px;
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
}
.template-featured-image
{
  height: 275px;
  object-fit: cover;
}

.sections-list-panel
{
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
}

.section-form
{
  max-width: 730px;
  > .card-body {
    //overflow: hidden;
    //overflow-y: auto;
    //max-height: calc(100vh - 300px) !important;

    &.client
    {
      //max-height: calc(100vh - 230px) !important;
    }
  }
  h6{

  }
}

.assessment-section-container
{
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 160px) !important;
}


@media (max-width: 991px) {
  .assessment-section-container
  {
    max-height: 100% !important;
    overflow: auto !important;
  }
  .section-form
  {
    > .card-body {
      max-height: 100% !important;
      overflow: auto !important;
    }
  }
}