.plan-card {
  padding: 40px;
  min-width: 320px;

  .card-title
  {
    font-size: 25px;
    font-weight: bold;
  }

  .pricing-head {
    color: $white;
  }

  .price {
    font-size: 35px;
    font-weight: bold;

    .period {
      font-size: 28px;
      margin-left: 5px;
    }
  }

  .features {
    font-size: 13px;
  }
  .intro-benefits {
    font-size: 13px;
  }
  &.pay_as_you_go {
    .pricing-head {
      color: $dark;
    }

    background-color: #CED4DA !important;

    .intro-benefits {
      color: $dark;
    }
    .features {
      color: $white;
    }
    li {
      .svg-inline--fa {
        color: $white;
      }
    }
  }
  &.pay_monthly {
    background-color: #343A3F !important;

    .intro-benefits {
      color: $primary;
    }
    .features {
      color: $white;
    }
    li {
      .svg-inline--fa {
        color: $white;
      }
    }
  }
  &.pay_yearly {
    background-color: $primary !important;

    .intro-benefits {
      color: $dark;
    }
    .features {
      color: $white;
    }
    li {
      .svg-inline--fa {
        color: $white;
      }
    }
  }

  .features {
    h5 {
      font-weight: bold;
    }
    ul {
      list-style-type: none;
      padding: 0;

    }
  }
}