.main-navbar {
    height: 67px;

    .navbar-nav {
        .nav-link {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
        }
    }
}