a {
    color: var(--bs-body-color);
    text-decoration: none !important;

    &:not(.btn) {
        &:hover {
            color: $primary !important;
        }
    }
}

.btn {
    justify-content: center;
    display: flex;
    align-items: center;
}
.btn-black {
    background-color: $black !important;
}

.text-deleted{
    text-decoration: Line-through;
}

.bg-none
{
    background: transparent !important;
}

.text-rating-orange {
    color: #E67F23 !important;
}

.inactive-btn {
    color: var(--bs-btn-disabled-color);
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
}

.question-container {
    border-radius: 0.375rem;
    border: 1px solid #CED4DA;
}

.counter-space {
    background-color: #DEE2E6;

    &.choices {
        border-radius: 0.375rem;
    }
}

.tox-tinymce {
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
}

.is-invalid {
    .tox-tinymce {
        border: 1px solid #dc3545;
    }
}

.repeatable-questions-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #6C757D;
}

.react-select {
    &.invalid > {
        div {

            border: 1px solid $danger;
            border-radius: 0.375rem;
        }
    }
}

.nowrap
{
    white-space: nowrap !important;
}
