.our-network
{
  background-color: #FCFAF5;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .listing-logo
  {
    width: 40px;

    &.lg {
      width: 85px;
      height: 85px;
    }
  }

  .shadow-card {
    box-shadow: 0 0 80px rgba(48, 47, 47, 0.1);
  }
}