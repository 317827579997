.my-account-tabs
{
  .nav-link:not(.active) {
    border: 1px solid $primary;
    padding: .4rem;
  }
  .nav-link{
    margin-right: 10px;
  }
}
.logo-uploads
{
  width: 115px;
}
.sign-uploads
{
  height: 116px;
}